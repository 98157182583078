@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .button {
    @apply bg-yellow-400 px-4 rounded text-2xl text-white font-bold;
  }
}
/* 웹뷰 페이지 */
.noticeDatail {
  color: #343434;
}
.noticeDatail h1 {
  font-size: 22px;
  font-weight: bold;
}
.noticeDatail h2 {
  font-size: 20px;
  font-weight: bold;
}
.noticeDatail h3 {
  font-size: 18px;
  font-weight: bold;
}

.noticeDatail p {
  font-size: 16px;
  line-height: 25px;
}

.noticeDatail li {
  margin-left: 20px;
  list-style: initial;
}

/* 병원/드라이버 정보 */
.orgInfo {
  font-size: 14px;
  margin-top: 8px;
  border: 1px solid #d4d4d4;
  padding: 8px 12px;
  border-radius: 6px;
}

/*  */
table {
  width: 100%;
  table-layout: auto;
  margin-top: 20px;
}
table thead {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  color: #454545;
  border-bottom-width: 1px;
  padding-bottom: 8px;
}

table thead th {
  text-align: center;
  padding: 4px 8px 12px;
}

table tbody tr {
  font-size: 14px;
  color: #454545;
  border-bottom-width: 1px;
}
table tbody tr:hover {
  background-color: #ebfaf4;
}

table tbody td {
  text-align: center;
  padding: 12px 8px;
}

button,
.btn {
  padding: 8px 26px;
  border-radius: 5px;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.form-section {
  position: relative;
  padding: 20px;
  border: 1px solid #aeaeae;
  border-radius: 4px;
  margin-bottom: 30px;
}
.form-section h2,
.section-title {
  font-size: 20px;
}
.form-section > h4,
.desc {
  font-size: 14px;
  color: #aaa;
  font-weight: 500;
}
.form-section label,
.label-default {
  font-size: 14px;
  font-weight: 700;
  color: #444;
}
.form-section .house-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #eee;
  cursor: pointer;
}
.form-section .house-image-container-plus {
  background-image: url(https://adm.ssnipani.com/plus.png);
  background-size: cover;
}

.house-tag {
  position: relative;
  width: 160px;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 2px;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 8px;
}

.horizontal-scroll-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
}
.horizontal-scroll-container > * {
  flex-shrink: 0;
}

.geocode-result {
  padding: 10px 5px;
  border: 1px solid #eee;
  background-color: #fff;
  cursor: pointer;
}
.geocode-result:hover {
  background-color: #eee;
}
.geocode-result > span {
  font-weight: 700;
  margin-right: 4px;
}

.editor-container {
  padding: 20px;
  border-bottom: 2px solid #ddd;
}

main {
  padding: 20px;
}
main > h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.feature-box {
  width: 140px;
  background-color: #eaeaea;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.feature-box-selected {
  background-color: #2cb4b3;
  color: #fff;
}
